.first-content{
    transform: scale(0.8);
    .content-title{
        line-height: 32px;
        letter-spacing: 3px;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    .content-description{
        color: #171B1B;
        font-family: PingFang SC;
        font-size: 48px;
        font-weight: 700;
        line-height: 64px;
        margin-bottom: 20px;
    }

    .center-content{
        width: 84%;
        padding:0 8%;
        margin-top: -80px;
        display: flex;
        justify-content: center;
        gap: 20%;
        .home-second-content{
            width: 510px;
            font-family: Alibaba PuHuiTi;
            margin-top: 50px;
            .content-exam{
                // display: flex;
                color: rgba(51, 51, 51, 0.50);
                font-family: Alibaba PuHuiTi;
                font-size: 20px;
                line-height: 32px; /* 160% */
                overflow : hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp:6;
                -webkit-box-orient: vertical;
            }
        }
        .home-two-back{
            width: 412px;
            height: 550px;
            background:url('../../../../assets/images/Slice 12.png') no-repeat;
            background-size:330px px;
            position: relative;
            img{
                height: 290px;
                width: 290px;
                position: absolute;
                margin-top: 140px;
            }
            .second-one-icon{
                transform: translateX(-110px);
                filter: blur(1.5px);
            }
            .second-two-icon{
                margin-top: 84px;
                z-index: 100;
                width: 390px;
                height: 390px;
                transform: translateX(-52px);
            }
            .second-three-icon{
                transform: translateX(135px);
                filter: blur(1.5px);
            }
            .second-four-icon{
                width: 84px;
                height: 84px;
                z-index: 101;
                transform: translate(300px,100px);
                cursor: pointer;
            }
        }
    }

    .honor-content{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 82%;
        padding:0 9%;
        margin: 200px 0 60px 0;
        .content-title-container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .content-right{
                display: flex;
                gap: 14px;
                // flex-direction: column;
                div{
                    width: 48px;
                    height: 48px;
                    border-radius: 48px;
                    background: #6FAA8A;
                    color: #F5D3AB;
                    text-align: center;
                    line-height: 48px;
                    font-size: 12px;
                    cursor: pointer;
                    transform: rotate(-90deg);
                }
            }
        }
        .honor-background{
            width: 100%;
            // .honoe-img{
            //     width: 100%;
            // }
        }
    }


    @media (max-width: 1600px) {
        .home-content,.center-content{
            width: 88%;
            padding:0 6%;
            gap: 10%;
     }
     .center-content{
        gap: 16%;
        }
    }
    @media (max-width: 1400px) {
        .five-content{
            width: 94%;
            padding:0 3%;
        }
    }
    @media (max-width: 1200px) {
        .home-content,.center-content{
            width: 94%;
            padding:0 3%;
            gap: 6%;
        }
        .five-content{
            width: 100%;
            padding:0;
        }
        .center-content{
            gap: 16%;
        }
    }
}