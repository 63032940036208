.fourth-content{
    width: 84%;
    padding:0 8%;
    transform: translateY(-250px);
    .product-all{
        border-radius: 12px;
        background: #FFF;
        padding: 40px 60px;
        transform: scale(0.9);
        .product-title{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-family: PingFang SC;
            font-style: normal;
            .title-one{
                color: #171B1B;
                font-size: 40px;
                font-weight: 700;
                line-height: 64px; /* 133.333% */
            }
            .title-two{
                color: #28976F;
                font-size: 40px;
                font-weight: 700;
                line-height: 64px;
            }
            .title-three{
                margin-left: 20px;
                color: #171B1B;
                font-family: Alibaba PuHuiTi;
                font-size: 16px;
                line-height: 32px; /* 200% */
                letter-spacing: 3px;
                text-transform: uppercase;
            }
        }

        .product-all-tag{
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            gap:30px;
            .category-tag{
                color: rgba(51, 51, 51, 0.50);
                text-align: center;
                font-family: PingFang SC;
                font-size: 20px;
                font-weight: 700;
                line-height: 28px; /* 133.333% */
                padding: 18px;
                border-radius: 51.5px;
                background: linear-gradient(90deg, #E9F6FF 0%, #EDF0FF 100%);
                cursor: pointer;
            }
            .category-active{
                color: #171B1B;
            }
        }

        .product-detail{
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            margin-top: 62px;
            .product-container{
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .product-card{
                width: 200px;
                height: 200px;
                background: url('../../../../assets/images/hexagon_fill.svg') no-repeat center center;
                // background-size: 220px 220px;
                display: flex;
                justify-content: center;
                // flex-direction: column;
                align-items: center;
            }
            .cate-title{
                display: inline-block;
                width: 105px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            img{
                // width: 120px;
                // height: 218px;
                max-height: 190px;
                max-width: 120px;
            }
            span{
                margin-top: 16px;
                display: inline-block;
                color: #333;
                text-align: center;
                font-family: PingFang SC;
                line-height: 32px; /* 200% */
            }
        }
    }

    .product-left{
        // width: 300px;
        min-width: 29%;
        .product-en{
            color: #171B1B;
            font-family: Alibaba PuHuiTi;
            line-height: 32px; /* 200% */
            letter-spacing: 3px;
            text-transform: uppercase;
            margin:0;
            font-size: 18px;
            font-weight: 400;
        }
        .product-zh{
            width: 220px;
            color: #171B1B;
            font-family: PingFang SC;
            font-size: 35px;
            font-weight: 700;
            line-height: 64px; /* 133.333% */
            margin: 20px 0;
        }
    }

    .product-modules-all{
        width: 100%;
        display: flex;
        // justify-content: space-between;
        margin-top: 120px;
        .product-right{
            // width: 100%;
            // width: 85%;
            display: flex;
            flex-wrap: wrap;
            gap: 25px;
            cursor: pointer;
        }
    }

    .product-descrip{
        margin-top: 40px;
        .p-title{
            color: #333;
            font-family: PingFang SC;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px; /* 133.333% */
        }
        .p-descrip{
            margin-top: 20px;
            color: rgba(51, 51, 51, 0.50);
            font-family: PingFang SC;
            font-size: 16px;
            line-height: 32px; /* 160% */
            width: 225px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }
    .right-img{
        height: 100%;
        display: flex;
        align-items: center;
    }

    .product-1,.product-3{
        .product-right{
            .product-item-0{
                width: 400px;
                height: 225px;
                background: #fff;
                overflow: hidden;
                padding: 22px;
                display: flex;
                gap: 27px;
                border-radius: 8px;
                .p-title{
                    width: 190px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                img{
                    max-width: 138px;
                    max-height: 225px;
                }
            }

            .product-item-1{
                width: 243px;
                height: 225px;
                flex-direction: column-reverse;
                gap: 0;
                img{
                    max-width: 243px;
                    max-height: 90px;
                }
                .product-descrip{
                    margin-top: 18px;
                    .p-title{
                        width: 225px;
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .p-descrip{
                        margin-top: 8px;
                        -webkit-line-clamp: 2;
                    }
                }
            }

            .product-item-2{
                width: 321px;
                height: 225px;
                gap: 0;
                .p-title{
                    width: 190px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .p-descrip{
                    width: 190px;
                }
                img{
                    max-width: 108px;
                    max-height: 196px;
                }
                .product-descrip{
                    margin-top: 20px;
                    width: 200px;
                }
            }
        }
    }

    .product-2{
        .product-right{
            .product-item-0{
                width: 176px;
                max-height: 423px;
                padding: 36px 27px;
                background: #fff;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                gap: 27px;
                border-radius: 8px;
                img{
                    max-width: 117px;
                    max-height: 252px;
                }
                .product-descrip{
                    margin-top: 0;
                    .p-title{
                        width: 175px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                    .p-descrip{
                        width: 175px;
                    }
                }
            }
        }

    }

    .product-4{
        .product-right{
            .product-item-0{
                width: 321px;
                height: 225px;
                background: #fff;
                overflow: hidden;
                padding: 22px;
                display: flex;
                gap: 0;
                border-radius: 8px;
                img{
                    width: 108px;
                    max-height: 196px;
                }
                .product-descrip{
                    // margin-top: 72px;
                    width: 218px;
                    .p-title{
                        width: 200px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    .p-descrip{
                        width: 200px;
                    }
                }
            }
        }
    }

    .product-0{
        .product-right{
            .product-item-0,.product-item-1{
                width: 321px;
                height: 225px;
                background: #fff;
                overflow: hidden;
                padding: 22px;
                display: flex;
                gap: 0;
                border-radius: 8px;
                img{
                    width: 108px;
                    max-height: 131px;
                }
                .product-descrip{
                    // margin-top: 72px;
                    width: 145px;
                    .p-title{
                        width: 145px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    .p-descrip{
                        width: 145px;
                    }
                }
            }
            .product-item-2{
                width: 693px;
                height: 211px;
                background: #fff;
                overflow: hidden;
                padding: 27px;
                display: flex;
                gap: 35px;
                img{
                    width: 220px;
                    max-height: 211px;
                }
                .product-descrip{
                    // margin-top: 54px;
                    width: 387px;
                    .p-title{
                        width: 387px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                    .p-descrip{width: 387px;}
                }
            }
        }
    }
}