.fourth-head{
    font-family: PingFang SC;
    color: #FFF;
    font-style: normal;
    width: 70%;
    padding:200px 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: scale(0.8);
    .fourth-title{
        text-align: center;
        font-size: 64px;
        font-weight: 700;
        margin-bottom: 20px;
    }
    .fourth-descrip{
        text-align: center;
        font-size: 24px;
        line-height: 40px; /* 166.667% */
        margin-bottom: 30px;
    }
}