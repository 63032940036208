.product-manage-container{
    .product-title{
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 16px;
    }
    .product-action{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }

}

.list-item{
     height: 20px;   
     padding: 16px 0 16px 16px;
     display: flex;
     align-items: center;
     border-bottom: 1px solid #eee;
     .list-content{
        width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
     }
     .drawer-action{
        //  display: ;
     }
     .list-action{
        padding: 0 !important;
    }
    .list-action-delete{
        padding: 0 !important;
        margin-right: 10px;
        color: rgb(211, 100, 100);
        &:hover{
            color: rgb(209, 44, 44) !important;
        }
    }
}