.product-manage-container{
    .product-title{
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 16px;
    }
    .product-action{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }
}

.tree-item-container{
    span{
        width: 170px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    .list-action{
        padding: 0 !important;
        margin-left: 10px;
    }
    .list-action-delete{
        padding: 0 !important;
        color: rgb(211, 100, 100);
        &:hover{
            color: rgb(209, 44, 44) !important;
        }
    }
}