.first-content{
    .home-content{
        // width: 86%;
        height: 700px;
        padding:0 5% 0 9%;
        display: flex;
        justify-content: center;
        gap: 6%;
        transform: scale(0.9);
        .home-first-back{
            margin-top:100px;
            width: 496px;
            height: 580px;
            position: relative;
            background:url('../../../../assets/images/Slice 10.png') no-repeat;
            background-size:400px 480px;
        }
        .first-one-icon{
            margin: -60px 0 0 -44px;
            height: 245px;
            width: 320px;
            position: absolute;
            // background: url('../../../../assets/images/first-one.png') no-repeat;
            // background-size: 400px 306px;
        }
        .first-two-icon{
            margin: 115px 0 0 48px;
            width: 319px;
            height: 319px;
            position: absolute;
            // background: url('../../../../assets/images/first-two.png') no-repeat;
            // background-size: 398px 398px
        }

        .home-first-content{
            width: 470px;
            // margin-top: 50px;
            font-family: Alibaba PuHuiTi;
            transform: scale(0.9);
            .content-subscription{
                color: rgba(51, 51, 51, 0.50);
                font-family: Alibaba PuHuiTi;
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 50px;
            }
            .content-img-container{
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 134px;
                .content-between{
                    width: 50%;
                    display: flex;
                    gap: 20px;
                    margin-bottom: 50px;
                    .content-img{
                        // width: 70px;
                        height: 75px;
                    }
                    .content-container{
                        font-family: Alibaba PuHuiTi;
                        .content-icon-title{
                            color: rgba(51, 51, 51, 0.50);
                            font-size: 19px;
                            line-height: 32px; /* 133.333% */
                        }
                        .content-icon-des{
                            color: #171B1B;
                            font-size: 26px;
                            font-weight: 700;
                            line-height: 32px; /* 100% */
                            .content-extra{
                                color: #171B1B;
                                font-size: 16px;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }
    .content-title{
        font-size: 20px;
        // line-height: 32px;
        letter-spacing: 3px;
        margin-bottom: 20px;
        font-weight: 400;
        text-transform: uppercase;
    }
    .content-description{
        color: #171B1B;
        font-family: PingFang SC;
        font-size: 38px;
        font-weight: 700;
        line-height: 64px;
        margin-bottom: 20px;
    }

    .center-content{
        // width: 84%;
        // padding:0 8%;
        margin-top: 80px;
        display: flex;
        justify-content: center;
        gap: 18%;
        transform: scale(0.9);
        .home-second-content{
            width: 450px;
            padding-left: 2%;
            font-family: Alibaba PuHuiTi;
            .content-exam{
                display: flex;
                .content-left{
                    width: 80px;
                    min-width: 80px;
                    margin-top: 72px;
                    display: flex;
                    gap: 14px;
                    flex-direction: column;
                    div{
                        width: 43px;
                        height: 43px;
                        border-radius: 43px;
                        background: #6FAA8A;
                        color: #F5D3AB;
                        text-align: center;
                        line-height: 43px;
                        font-size: 12px;
                        cursor: pointer;
                    }
                }
                .content-right{
                    width: 333px;
                    padding-top: 30px;
                    .content-second-title{
                        color: #171B1B;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 26px; /* 133.333% */
                        margin-bottom: 20px;
                    }
                    .content-des{
                        width: 333px;
                        // height: 96px;
                        color: rgba(51, 51, 51, 0.50);
                        line-height: 29px; 
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                    .content-next-title{
                        margin-top: 20px;
                        color: #171B1B;
                        line-height: 29px; /* 200% */
                    }
                }
            }
        }
        .home-two-back{
            width: 371px;
            height: 495px;
            background:url('../../../../assets/images/Slice 12.png') no-repeat;
            background-size:371px 468px;
            position: relative;
            img{
                height: 261px;
                width: 261px;
                position: absolute;
                margin-top: 140px;
            }
            .second-one-icon{
                transform: translateX(-135px);
                filter: blur(1.5px);
            }
            .second-two-icon{
                margin-top: 84px;
                z-index: 100;
                width: 351px;
                height: 351px;
                transform: translateX(-50px);
            }
            .second-three-icon{
                transform: translateX(130px);
                filter: blur(1.5px);
            }
            .second-four-icon{
                width: 70px;
                height: 70px;
                z-index: 101;
                transform: translate(270px,100px);
                cursor: pointer;
            }
        }
    }

    .three-content{
        margin-top: 150px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .three-img-container{
            margin-top: 60px;    
            height: 396px;
            display: flex;
            .three-img-card{
                position: relative;
                cursor: pointer;
                width: 160px;
                height: 250px;
                padding: 30px 20px;
                background: #fff;
                overflow: hidden;
                span{
                    display: inline-block;
                    margin-bottom:90px;
                    color: #171B1B;
                    font-family: Oswald;
                    line-height: 32px; /* 200% */
                    letter-spacing: 3px;
                    text-transform: uppercase;
                }
                .three-title{
                    color: #171B1B;
                    font-family: Ultra;
                    font-size: 20px;
                    font-style: normal;
                    line-height: 48px; /* 200% */
                    font-weight: 600;
                }
                .three-descrip{
                    margin-top: 20px;
                    color: rgba(51, 51, 51, 0.50);
                    font-family: Alibaba PuHuiTi;
                    width: 160px;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
                .three-icon{
                    display: none;
                    width: 160px;
                    height: 160px;
                    border-radius: 160px;
                    position: absolute;
                    top: -40px;
                    right: -30px;
                }

                @keyframes mouse-in {
                    0% {
                        width: 160px;
                        height: 250px;
                    }
                    1%{
                        background: 
                        url('../../../../assets/images/Slice 10@2x.png') bottom 50% left 50% no-repeat;
                    }
                    100% {
                        transform: scale(1.1);
                        background: 
                        url('../../../../assets/images/Slice 10@2x.png') bottom 50% left 50% no-repeat;
                    }
                }
                &:hover{
                    animation-name: mouse-in;
                    animation-timing-function: ease-in-out;
                    animation-duration: 0.5s;
                    animation-fill-mode: forwards;
                    z-index: 100;
                    span{
                        color: #fff;
                    }
                    .three-title{
                        color: #fff;
                    }
                    .three-descrip{
                        color: #fff;
                    }
                    .three-icon{
                        display: inline-block;
                    }
                }
            }   

            .three-img-active{
                transform: scale(1.1);
                background: 
                url('../../../../assets/images/Slice 10@2x.png') bottom 50% left 50% no-repeat;
                z-index: 100;
                span{
                    color: #fff;
                }
                .three-title{
                    color: #fff;
                }
                .three-descrip{
                    color: #fff;
                }
                .three-icon{
                    display: inline-block;
                }
            }
        }
        // @media (max-width: 1600px) {
        //     transform: scale(0.8);
        // }
        // @media (max-width: 1200px) {
        //     transform: scale(0.6);
        // }
    }
    

    .four-content-contain{
        height: 580px;
        width: 100%;
        background:url('../../../../assets/images/Slice 11.png') no-repeat;
        background-size: 100% 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
        .four-title{
            margin-top: 156px;
            color: #FFF;
            font-family: PingFang SC;
            font-size: 36px;
            line-height: 68px; /* 170% */
        }
        .four-description{
            width: 522px;
            margin-top:20px;
            color: #FFF;
            text-align: center;
            font-family: PingFang SC;
            font-size: 21px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px; /* 166.667% */
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    .five-content{
        margin-top: 100px;
        transform: scale(0.9);
        padding-left: 2%;
        .home-two-back{
            width: 504px;
            height: 495px;
            background:url('../../../../assets/images/Slice 13.png') no-repeat;
            background-size: 504px 495px;
            position: relative;
            img{
                height: 280px;
                width: 290px;
                margin-top: 120px;
            }
            .second-one-icon{
                transform: translateX(30px);
            }
            .second-two-icon{
                margin-top: 60px;
                width: 350px;
                height: 385px;
                transform: translateX(110px);
            }
            .second-three-icon{
                transform: translateX(270px);
            }
            .second-four-icon{
                transform: translate(420px, 120px);
            }
            .second-four-icon-two{
                transform: rotate(180deg) translate(-80px, -120px);
            }
        }
        .home-second-content{
            .conten-sub{
                color: rgba(51, 51, 51, 0.50);
                font-family: PingFang SC;
                font-size: 16px;    
                line-height: 32px; /* 200% */
                width: 370px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }

    // @media (max-width: 1600px) {
    //     .home-content,.center-content{
    //         width: 88%;
    //         padding:0 6%;
    //         gap: 10%;
    //  }
    //  .center-content{
    //     gap: 16%;
    //     }
    // }
    // @media (max-width: 1400px) {
    //     .five-content{
    //         width: 94%;
    //         padding:0 3%;
    //     }
    // }
    // @media (max-width: 1200px) {
    //     .home-content,.center-content{
    //         width: 94%;
    //         padding:0 3%;
    //         gap: 6%;
    //     }
    //     .five-content{
    //         width: 100%;
    //         padding:0;
    //     }
    //     .center-content{
    //         gap: 16%;
    //     }
    // }
}