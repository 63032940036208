.new-container{
    margin: 80px auto;
    // width: 84%;
    // padding: 0 8%;
    transform: scale(0.8);
    .new-header{
        display: flex;
        justify-content: center;
        gap: 30px;
        .new-title{
            width: 570px;
            color: #171B1B;
            font-family: Alibaba PuHuiTi;
            font-size: 16px;
            line-height: 32px; /* 200% */
            letter-spacing: 3px;
            text-transform: uppercase;
            font-weight: 400;
            // margin: 0;
            }
            .new-action{
                width: 270px;
            }
    }

    .new-header-two{
        display: flex;
        justify-content: center;
        gap: 30px;
        div{
            width: 270px;
        }
        .new-title{
            width: 570px;
            color: #171B1B;
            font-family: PingFang SC;
            font-size: 48px;
            font-weight: 700;
            margin: 0;
            line-height: 64px; /* 133.333% */
        }
        .new-action{
            display: flex;
            justify-content: end;
               span{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 154px;
                height: 42px;
                margin: 20px 0 40px 0;
                color: #28976F;
                font-family: Oswald;
                border-radius: 2px;
                border: 1px solid #28976F;
                font-size: 16px;
                line-height: 32px; /* 200% */
                cursor: pointer;
               }
            }
    }
   
    .new-item{
        margin-top: 40px;
        display: flex;
        justify-content: center;
        gap: 30px;
        .new-left{
            cursor: pointer;
            width: 570px;
            height: 736px;
            background: #fff;
            // background: url('../../assets/images/news-one.png') no-repeat top left 25% #fff;
            // background-size: 570px 496px;
            img{
                width: 570px;
                height: 496px;
            }
            .new-time{
                display: flex;
                justify-content: space-between;
                padding: 30px 40px 20px 40px;
                .new-time-left{
                    color: #171B1B;
                    font-family: Oswald;
                    font-size: 24px;
                    line-height: 32px; /* 160% */
                    span{
                        font-size: 20px;
                        margin-left: 10px;
                    }
                }
                a{
                    color: #28976F;
                    font-family: PingFang SC;
                    font-size: 20px;
                    line-height: 24px; /* 120% */
                    cursor: pointer;
                }
            }
            .new-title{
                margin:0 40px 20px 40px;
                color: #171B1B;
                font-family: PingFang SC;
                font-size: 36px;
                font-style: normal;
                font-weight: 700;
                line-height: 48px; /* 133.333% */
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
            .new-descrip{
                margin:0 40px 0 40px;
                color: rgba(51, 51, 51, 0.50);
                font-family: PingFang SC;
                font-size: 16px;
                line-height: 32px; /* 200% */
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

     .new-right{
            cursor: pointer;
            width: 270px;
            height: 353px;
            // background: url('../../assets/images/news-two.png') no-repeat top left 25% #fff;
            // background-size: 270px 208px;
            background-color: #fff;
            img{
                width: 270px;
                height: 200px;
            }
            .new-title{
                color: #171B1B;
                font-family: PingFang SC;
                font-size: 20px;
                line-height: 30px; /* 160% */
                padding: 8px 20px 2px 20px;/* 133.333% */
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                font-weight: 400;
                margin: 0;
            }
            .new-descrip{
                color: rgba(51, 51, 51, 0.50);
                padding: 0 20px;/* 133.333% */
                font-family: PingFang SC;
                font-size: 16px;
                line-height: 30px; /* 200% */
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .new-time-right{
                padding: 8px 20px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                span{
                    margin-left: 4px;
                    font-size: 16px;
                    color: #171B1B;
                    font-family: Oswald;
                    font-weight: 300;
                }
            }
        }
    }
}