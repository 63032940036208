.new-container{
    margin: -70px 0 120px 0;
    width: 84%;
    padding: 0 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: scale(0.8);
    .new-header,.new-header-two,.new-header-three{
        display: flex;
        justify-content: center;
        gap: 30px;
        .new-title{
            width: 570px;
            color: #171B1B;
            font-family: Alibaba PuHuiTi;
            font-size: 16px;
            line-height: 32px; /* 200% */
            letter-spacing: 3px;
            text-transform: uppercase;
            }
            .new-action{
                width: 270px;
            }
    }

    .new-header-two{
        .new-title{
            width: 1170px;
            margin: 20px 0;
            color: #171B1B;
            font-family: PingFang SC;
            font-size: 48px;
            font-weight: 700;
            line-height: 64px; /* 133.333% */
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }
    .new-header-three{
        .new-title{
            width: 1170px;
            margin-bottom: 40px;
            color: rgba(51, 51, 51, 0.50);
            font-family: Alibaba PuHuiTi;
            font-size: 20px;
            line-height: 32px; /* 160% */
        }
    }
   
    .new-item{
        width: 1170px;
        display: grid;
        /* 可以看到，网格大小，占据位置是需要提前设定的 */
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap:30px;
        grid-auto-rows: 10px;
        .new-right{
            width: 33%;
            background: #fff;
            width: 370px;
            height: 340px;
            grid-row-start:'auto';
            grid-row-end:span 37;
            img{
                width: 370px;
                height: 195px;
            }
            .new-time{
                font-family: Oswald;
                font-size: 20px;/* 160% */
                margin: -270px 0 20px 48px;
            }
            .new-title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                margin:20px 20px 5px 20px;
                div{
                    max-width: 800px;
                    color: #171B1B;
                    font-family: PingFang SC;
                    font-size: 20px;
                    line-height: 32px; /* 160% */
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
                .new-action{
                    display: inline-block;
                    min-width: 100px;
                    color: #28976F;
                    font-family: PingFang SC;
                    font-size: 20px;
                    line-height: 32px; /* 160% */
                    cursor: pointer;
                }
            }
            .new-descrip{
                width: calc(100% - 40px);
                margin:0 20px 20px 20px;
                color: rgba(51, 51, 51, 0.50);
                font-family: PingFang SC;
                font-size: 16px;
                line-height: 32px; /* 200% */
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            &:nth-child(1){
                width: 770px;
                height: 400px;
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start:'auto';
                grid-row-end:span 43;
                img{
                    width: 770px;
                    height: 250px;
                }
            }
            &:nth-child(2){
                width: 370px;
                height: 770px;
                grid-row-start:'auto';
                grid-row-end:span 79;
                img{
                    width: 370px;
                    height: 552px;
                }
                .new-descrip{
                    -webkit-line-clamp: 4;
                }
            }
        }
    }
    
    
    .new-action-footer{
        display: flex;
        justify-content: center;
        margin: 90px 0;
        span{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 154px;
            height: 42px;
            margin: 20px 0 40px 0;
            color: #28976F;
            font-family: Oswald;
            border-radius: 2px;
            border: 1px solid #28976F;
            font-size: 16px;
            line-height: 32px; /* 200% */
            cursor: pointer;
        }
    }

    // @media (max-width:1422px) {
    //     transform: scale(0.8);
    //     margin-top: -50px;
    // }
}