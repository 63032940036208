.seven-content{
    width: 76%;
    padding: 0 12%;
    transform: translateY(-260px);
    .card-container{
        border-radius: 12px;
        background: #FFF;
        box-shadow: 0px 20px 60px 0px rgba(245, 211, 171, 0.12);
        padding: 60px;
    }
    .address-container{
        .address-title-en{
            color: #171B1B;
            font-family: Alibaba PuHuiTi;
            line-height: 32px; /* 200% */
            letter-spacing: 3px;
            text-transform: uppercase;
            margin-top: 120px;
            text-align: center;
        }
        .address-title-zh{
            color: #171B1B;
            font-family: PingFang SC;
            font-size: 48px;
            font-weight: 700;
            line-height: 64px; /* 133.333% */
            margin: 20px 0 60px 0;
                text-align: center;
        }
    }
    .map-container{
        width: 100%;
        height: 600px;
        display: flex;
        align-items: flex-end;
        position: relative;
        .map-root{
            width: 100%;
            height: 100%;
        }
        .map-footer{
            width: 88%;
            margin: 30px 3.1%;
            height: 80px;
            border-radius: 4px;
            background: #FFF;
            box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.10);
            padding: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            .footer-left{
                display: flex;
                align-items: center;
                .footer-img-contianer{
                    margin-right: -20px;
                    .footer-img-1{
    
                    }
                    .footer-img-2{
                        transform: translate(-60px, -15px);
                    }
                }
                .footer-descrip{
                    display: flex;
                    flex-direction: column;
                    font-family: PingFang SC;
                    .footer-title{
                        color: #333;
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 32px; /* 133.333% */
                        letter-spacing: -1px;
                    }
                    .footer-sub{
                        color: rgba(51, 51, 51, 0.50);
                        font-size: 16px;
                        line-height: 32px; /* 200% */
                    }
                }
            }
            .footer-button{
                width: 182px;
                height: 42px;
                flex-shrink: 0;
                border-radius: 2px;
                border: 1px solid #28976F;
                color: #28976F;
                font-family: Oswald;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 42px; /* 200% */
                text-align: center;
                cursor: pointer;
            }

         
        }
    }

    :global{
        .ant-form-item .ant-form-item-label >label{
            color: #000;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            padding: 0 0 18px;
        }
        .ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
            content: none;
        }
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
            border-radius: 12px;
            height: 56px;
            padding: 12px 20px;
        }
        .ant-input{
            border-radius: 12px;
            height: 56px;
            padding: 16px 20px;
            &:focus{
                border: 1px solid #28976F;
            }
            &:hover{
                border: 1px solid #28976F;
            }
        }
        .ant-btn-primary{
            background: #28976F;
            background-color: #28976F;
            border-radius: 10px;
            width: 248px;
            height: 60px;
            flex-shrink: 0;
        }
        .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover{
            background-color: #28976F;
        }
        .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active{
            background-color: #28976F;
        }

        .amap-logo,.amap-copyright{
            display: none !important;
            visibility: hidden !important;
        }
    }
}