.second-head{
    font-family: PingFang SC;
    color: #FFF;
    font-style: normal;
    width: 70%;
    padding:200px 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(-800px) scale(0.8);
    height: 0;
    .second-title{
        text-align: center;
        font-size: 64px;
        font-weight: 700;
        margin-bottom: 20px;
    }
    .second-content{
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        // &:hover{
        //     border: 1px solid #28976F;
        // }
        .suffix-button{
            color: #FFF;
            font-family: PingFang SC;
            font-size: 18px;
            width: 116px;
            height: 44px;
            border-radius: 10px;
            background: #28976F;
        }
        .prefix-img{

        }
    }
    :global{
        .ant-input-affix-wrapper:focus{
            border-color: #28976F;
        }
        .ant-input-prefix{
            margin-right: 14px;
        }
        .ant-input-affix-wrapper >input.ant-input{
            font-size: 18px;
        }
        .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
            color:#fff;
            border-color: #28976F;
        }
        .ant-btn-default:not(:disabled):not(.ant-btn-disabled):active{
            color:#fff;
            border-color: #28976F;
        }
    }
    .second-action-tab{
        width: 100%;
        max-width: 1000px;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        font-family: PingFang SC;
        margin-top: 25px;
        gap: 25px;
        span{
            color: #FFF;
            line-height: 32px; /* 200% */
        }
        .tab-button{
            width: 112px;
            height: 42px;
            flex-shrink: 0;
            border-radius: 8px;
            border: 2px solid #FFF;
            color: #FFF;
            text-align: center;
            line-height: 42px; /* 200% */
            text-align: center;
            padding: 5px 30px;
            cursor: pointer;
        }
    }
}

.content-container{
    transform: translateY(-400px);
}