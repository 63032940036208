.new-container{
    margin: 80px 0;
    width: 84%;
    padding: 0 8%;
    transform: scale(0.8);
    .new-header{
        display: flex;
        justify-content: center;
        gap: 30px;
        .new-title{
            font-weight: 400;
            margin: 0;
            width: 570px;
            color: #171B1B;
            font-family: Alibaba PuHuiTi;
            font-size: 16px;
            line-height: 32px; /* 200% */
            letter-spacing: 3px;
            text-transform: uppercase;
            }
            .new-action{
                width: 270px;
            }
    }

    .new-header-two{
        display: flex;
        justify-content: center;
        gap: 30px;
        div{
            width: 270px;
        }
        .new-title{
            width: 570px;
            color: #171B1B;
            font-family: PingFang SC;
            font-size: 48px;
            font-weight: 700;
            line-height: 64px; /* 133.333% */
        }
        .new-action{
            display: flex;
            justify-content: end;
               span{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 154px;
                height: 42px;
                margin: 20px 0 40px 0;
                color: #28976F;
                font-family: Oswald;
                border-radius: 2px;
                border: 1px solid #28976F;
                font-size: 16px;
                line-height: 32px; /* 200% */
                cursor: pointer;
               }
            }
    }
   
    .new-item{
        margin-top: 40px;
        display: flex;
        justify-content: center;
        gap: 30px;
        cursor: pointer;
        .new-left{
            width: 770px;
            height: 448px;
            color: #FFF;
            img{
                width: 770px;
                height: 448px;
            }
            .new-time{
                font-family: Oswald;
                font-size: 20px;/* 160% */
                margin: -270px 0 20px 48px;
            }
            .new-title{
                width: 320px;
                margin:0 48px 20px 48px;
                font-family: PingFang SC;
                font-size: 40px;
                font-weight: 700;
                line-height: 48px; /* 133.333% */
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .new-descrip{
                width: 524px;
                margin:0 48px 0 48px;
                font-family: PingFang SC;
                line-height: 32px; /* 200% */
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
    
    
    .new-right{
        width: 370px;
        height: 448px;
        cursor: pointer;
        // background: url('../../assets/images/news-two.png') no-repeat top left 25% #fff;
        // background-size: 270px 208px;
        background-color: #fff;
        img{
            width: 370px;
            height: 300px;
        }
        .new-title{
            color: #171B1B;
            font-family: PingFang SC;
            font-size: 20px;
            line-height: 30px; /* 160% */
            padding: 8px 20px 2px 20px;/* 133.333% */
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .new-descrip{
            color: rgba(51, 51, 51, 0.50);
            padding: 0 20px;/* 133.333% */
            font-family: PingFang SC;
            font-size: 16px;
            line-height: 30px; /* 200% */
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .new-time-right{
            padding: 5px 20px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            span{
                margin-left: 4px;
                font-size: 16px;
                color: #171B1B;
                font-family: Oswald;
                font-weight: 300;
            }
        }
    }

    .foot-contain{
        margin-top: 30px;
        display: flex;
        justify-content: center;
        gap: 30px;
    }
    @media (max-width:1422px) {
        transform: scale(0.8);
    }
}