.new-container{
    margin: 120px 0;
    width: 80%;
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    .new-header{
        display: flex;
        justify-content: center;
        gap: 30px;
        .new-title{
            width: 456px;
            font-weight: 400;
            margin: 0;
            color: #171B1B;
            font-family: Alibaba PuHuiTi;
            font-size: 13px;
            line-height: 29px; /* 200% */
            letter-spacing: 2px;
            text-transform: uppercase;
            }
            .new-action{
                width: 216px;
            }
    }

    .new-header-two{
        display: flex;
        justify-content: center;
        gap: 24px;
        margin:8px 0 28px 0;
        div{
            width: 220px;
        }
        .new-title{
            width: 320px;
            min-width: 72%;
            color: #171B1B;
            font-family: PingFang SC;
            font-size: 38px;
            font-weight: 700;
            margin: 0;
            line-height: 64px; /* 133.333% */
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        .new-tab{
            display: flex;
            justify-content: end;
            align-items: center;
            min-width: 90%;
            .tab-item{
                height: 27.2px;
                display: inline-block;
                padding: 0 24px;
                border-radius: 2px;
                font-family: Source Han Sans CN;
                line-height: 26px; /* 200% */
                cursor: pointer;
                color: rgba(51, 51, 51, 0.50);
                }
                .active-tab{
                    background: #28976F;
                    color: #FFF;
                }
            }
    }
   
    .new-item{
        width: 936px;
        display: grid;
        /* 可以看到，网格大小，占据位置是需要提前设定的 */
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap:20px;
        grid-auto-rows: 8px;
        min-height: 400px;
        // height: fit-content !important;
        .new-right{
            width: 33%;
            background: #fff;
            width: 296px;
            height: 420px;
            grid-row-start:'auto';
            grid-row-end:span 55;
            img{
                width: 296px;
                height: 256px;
            }
            .new-time{
                font-family: Oswald;
                font-size: 16px;/* 160% */
                margin: -173px 0 16px 38px;
            }
            .new-title{
                width: 256px;
                margin:16px 16px 4px 16px;
                color: #171B1B;
                font-family: PingFang SC;
                font-size: 16px;
                line-height: 30px; /* 160% */
                overflow: hidden;
                font-weight: 400;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .new-descrip{
                width: 264px;
                height: 51px;
                margin:0 20px 18px 20px;
                color: rgba(51, 51, 51, 0.50);
                font-family: PingFang SC;
                font-size: 13px;
                line-height: 24px; /* 200% */
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .new-time-right{
                display: flex;
                justify-content: space-between;
                padding: 0 16px;
                span{
                    color: #28976F;
                    font-family: PingFang SC;
                    font-size: 16px;
                    line-height: 26px; /* 160% */
                    cursor: pointer;
                }
                .new-time-time{
                    color: #171B1B;
                    font-family: Oswald;
                    font-size: 16px;
                    line-height: 26px; /* 160% */
                }
            }
        }
    }
    
    
    .new-action-footer{
        display: flex;
        justify-content: center;
        margin: 72px 0;
        span{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 123px;
            height: 34px;
            margin: 16px 0 32px 0;
            color: #28976F;
            font-family: Oswald;
            border-radius: 2px;
            border: 1px solid #28976F;
            font-size: 13px;
            line-height: 26px; /* 200% */
            cursor: pointer;
        }
    }

    // @media (max-width:1422px) {
        
    // }
}