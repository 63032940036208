.container{
    width: 100%;
    // height: 100vh;
    overflow-y: auto;
    .demo-logo-vertical{
        width: 100%;
        height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 6px;
        img{
            // width: 170px;
            height: 48px;
        }
    }
    .head-container{
        height: 52px;
        padding: 0 24px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .user-icon{
            width: 32px;
            height: 32px;
            border-radius: 32px;
        }
    }
    .aside-container{
        height: 100vh;
        position: fixed
    }
    .content-layout{
        // margin-left: 180px;
    }
    .content-container{
        margin: 16px 16px;
        padding: 24px;
        border-radius: 8px;
        min-height: 280;
        background: #fff;
        min-height: calc(100vh - 84px);
        overflow-x: auto;
        .head-button{
            font-size: 16px;
            width: 64px;
            height: 64px;
        }
    }
}
:global{
    .ant-menu-item-icon{
        margin-right: 12px;
    }
    .ant-menu-inline.ant-menu-root .ant-menu-item{
        height: 48px;
    }
    .admin-dropdown .ant-dropdown-menu{
        width:78px;
        padding: 0;
        transform:translateX(0);
        .ant-dropdown-menu-item:hover{
            color: #171B1B;
            background: #fff;
        }
    }
    .admin-dropdown .ant-dropdown-menu .ant-dropdown-menu-item{
        width:78px;
        justify-content: center;
        align-items: center;
    }
    .ant-layout .ant-layout-sider-children{
        // position: fixed;
        // width: 170px;
    }
    .ant-form-item{
        margin-bottom: 16px;
    }
    .ant-form-item .ant-form-item-label >label{
        color: #666;
    }
}