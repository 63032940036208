.home-container{
    height: 100vh;
    background:#F5EEE6;
    overflow-y: auto;
    .nav-contianer{
        position: sticky;
        top: 0;
        z-index: 102;
        width: 82%;
        padding: 0 9%;
        height: 54px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.20);
        color: #FFF;
        font-family: PingFang SC;
        font-style: normal;
        .header-title{
            width: 126px;
            height: 42px;
            background:url('../../assets/images/header_title.png') no-repeat;
            background-size: 126px 42px;
         }
         .header-tab{
             display: inline-flex;
             flex-direction: row;
             gap: 42px;
            li{
                height: 54px;
                line-height: 54px;
                text-align: center;
                list-style: none;
                box-sizing: border-box;
                cursor: pointer;
                font-size: 14px;
                &:hover{
                    border-bottom: 3px solid #fff;
                    text-shadow: 0 0 4px #fff;
                }
                &:last-child{
                    border-bottom:none
                }
            }
            .li-active{
                border-bottom: 3px solid #fff;
                text-shadow: 0 0 4px #fff;
            }
            @media (max-width: 1200px) {
                li{
                    font-size: 12px;
                }
            }
            .header-button{
                width: 92px;
                height: 34px;
                background: transparent;
                color: #fff;
            }
            :global{
                .ant-btn-default:hover{
                    color: #fff;
                    border: 1px solid #fff;
                }
            }
         }
    }
    
    .nav-contian-fixed{
        background: rgba(255, 255, 255, 0.50);
        box-shadow: 0px 0px 30px 4px rgba(255, 255, 255, 0.55) inset, 0px 8px 20px 0px rgba(52, 203, 157, 0.06);
        backdrop-filter: blur(10px);
        .header-title{
            background:url('../../assets/images/header-icon-fixed.png') no-repeat;
            background-size: 126px 42px;
         }
         .header-tab{
            li{
                color: #305246;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Source Han Sans CN;
                &:hover{
                    color: #28976F;
                    border-bottom: 3px solid #28976F;
                    text-shadow: 0 0 1px #28976F;
                }
                &:last-child{
                    border-bottom:none
                }
            }
            .li-active{
                border-bottom: 3px solid #28976F;
                text-shadow: 0 0 1px #28976F;
            }
            .header-button{
                color: #305245;
                border: 1px solid #305245;
            }
            :global{
                .ant-btn-default{
                    color: #305245;
                    border-radius: 2px;
                    border: 1px solid #305245;
                }
                .ant-btn-default:hover{
                    color: #28976F;
                    border: 1px solid #28976F;
                }
            }
         }
    }
    
    .header-container{
        width:100%;
        color: #FFF;
        font-family: PingFang SC;
        font-style: normal;
        min-height: 680px;
        background:url('../../assets/images/Slice 14.png') no-repeat;
        background-size: 100% 600px;
        position: relative;
        top: -54px;
    }
}

.detail-back{
    background: rgba(255, 255, 255, 0.20);
}

:global{
    .ant-dropdown-menu{
        transform: translateX(-45px);
        width: 128px;
    }
    .ant-dropdown .ant-dropdown-menu{
        padding: 8px 0 0 0;
        margin: -1px;
    }
    .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item{
        width: 128px;
        height: 40px;
        text-align: center;
        border-radius: 0;
        color: #171B1B;
        font-size: 12px;
        &:hover{
            color: #fff;
            background: rgba(40, 151, 111, 0.80);
        }
        &:last-child{
            border-radius:  0 0 8px 8px;
        }
    }
}